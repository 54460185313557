import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import { searchAgentsService } from "../../services/searchAgentsService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import getValuesInObject from "../../jsx/utils/getValuesInObject";

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const filterRef = useRef();
  const filterText = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentsDefault, setAgentsDefault] = useState(null);
  const [states, setStates] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [modalDetails, setModalDetails] = useState(false);
  const [parameterDownload, setParameterDownload] = useState("");
  const transactionTypes = [
    { label: "commission", value: "commission" },
    { label: "payout", value: "payout" },
    { label: "chargeback", value: "chargeback" },
  ];

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const [form, setForm] = useState({
    pk: null,
    name: "",
    created_at: "",
    updated_at: "",
    state_id: null,
    premium_amount: null,
    product_id: null,
    agent_id: null,
  });

  const onHidde = () => {
    console.log("hidde");
  };

  const searchAgents = (e) => {
    searchAgentsService(agentsDefault, setAgents, e);
  };

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    setLoadingFilter(true);
    let filtersActive = getValuesInObject(formLabels);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter =
      getQueryString(paginationTemp) + (filterText.current || "") + filterModal;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Fields",
          inputs: [
            {
              label: "Agent",
              input: "selectAutoCompleteSearch",
              inputValue: form.agent_id || {},
              checkbox: true,
              checkboxValue: form.agent_id ? true : false,
              checkboxName: "checkbox_agents",
              options: agents,
              setOptions: searchAgents,
              key: "agent_id",
            },
            {
              label: "State",
              input: "selectAutoComplete",
              inputValue: form.state_id || {},
              checkbox: true,
              checkboxValue: form.state_id ? true : false,
              checkboxName: "checkbox_state",
              options: states,
              key: "state_id",
            },
            {
              label: "Product",
              input: "selectAutoComplete",
              inputValue: form.product_id || {},
              checkbox: true,
              checkboxValue: form.product_id ? true : false,
              checkboxName: "checkbox_product_id",
              options: products,
              key: "product_id",
            },
            {
              label: "Effective Date on or After",
              input: "date",
              inputValue: form.effective_after || "",
              checkbox: true,
              checkboxValue: form.effective_after ? true : false,
              checkboxName: "checkbox_after",
              key: "effective_after",
            },
            {
              label: "Effective Date on or Before",
              input: "date",
              inputValue: form.effective_before || "",
              checkbox: true,
              checkboxValue: form.effective_before ? true : false,
              checkboxName: "checkbox_before",
              key: "effective_before",
            },
          ],
        },
      ],
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          policy_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    }

    if (!filterText.current) {
      await petition(parameter, false, false, false, false, filtersActive);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("first_name");
      await petition(parameter, false, true, value, false, filtersActive);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    resetForm();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter =
        getQueryString(paginationTemp) +
        (filterText.current || "") +
        (filterModal.current || "");

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[2] : false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) +
        (filterText.current || "") +
        (filterModal.current || "");
      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[2] : false,
        pagination.current,
        objectFilter.current
      );
    }
  };

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);
    let agentsTemp = [];
    let productsTemp = [];
    let statesTemp = [];

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;

    await petitionGet("states", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        statesTemp = result.result;
        setStates(result.result);
      })
      .catch((error) => {});
    await petitionGet("products", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        productsTemp = result.result;
        setProducts(result.result);
      })
      .catch((error) => {});
    await petitionGet("agents", { id, parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.first_name + " " + element.last_name;
        });
        agentsTemp = result.result;
        setAgents(result.result);
      })
      .catch((error) => {});
    await petitionGet("policies", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = element.created_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.completeName = element.first_name + " " + element.last_name;
          element.stateName = statesTemp.find(
            (e) => e.pk === element.state_id
          ).name;
          element.stateValue = statesTemp.find(
            (e) => e.pk === element.state_id
          ).value;
          element.agentName = agentsTemp.find(
            (e) => e.pk === element.agent_id
          ).label;
          element.agentValue = agentsTemp.find(
            (e) => e.pk === element.agent_id
          ).value;
          element.productName =
            productsTemp.find((e) => e.pk === element.product_id)?.label ||
            "Not Exist";
          element.effective_date = element.effective_date.slice(0, 10);
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "NAME", key: "completeName" },
            { title: "PRODUCT", key: "productName" },
            { title: "POLICY NUMBER", key: "policy_number" },
            { title: "PREMIUM AMOUNT", key: "premium_amount" },
            { title: "AGENT", key: "agentName" },
            { title: "STATE", key: "stateName" },
            { title: "EFFECTIVE DATE", key: "effective_date" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: (e) => {
                  activeEditModal(e);
                },
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          search: {
            label: "Search by Policy Holders Name, Policy Number",
            filterBackend: true,
            onChange: (e) => filterTextBox(e.target.value),
            defaultValue: valueFilter || null,
          },
          addButton: sub_agency_id ? null : {
            label: "Add Policies",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => {
            if (activeFilterTexBox) {
              activeItemsPerPage(e, true, valueFilter);
            } else {
              activeItemsPerPage(e);
            }
          },
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, valueFilter);
            } else {
              changePage(page);
            }
          },
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    setLoadingModal(true);
    let data = {
      policy_number: form.policy_number,
      premium_amount: form.premium_amount,
      date_of_birth: form.date_of_birth,
      agent_id: parseInt(form.agent_id),
      product_id: form.product_id,
      state_id: form.state_id,
      first_name: form.first_name,
      last_name: form.last_name,
      effective_date: form.effective_date,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (info?.pk) {
      petitionPatch("policies", {
        policies_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("policies", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("policies", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
    });
  };

  const changePage = (page, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&policy_number=${valueFilter}&first_name=${valueFilter}${filterRef.current}`;
      petition(
        parameter,
        true,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        true,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    }
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(
        paginationTemp
      )}&policy_number=${value}&first_name=${value}${filterRef.current || ""}`;
      petition(
        parameter,
        true,
        true,
        value,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;

      if (value) {
        filterText.current = `&policy_number=${value}&first_name=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        true,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&policy_number=${valueFilter}&first_name=${valueFilter}${filterRef.current}`;

      petition(
        parameter,
        false,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        false,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        const filters = result?.result?.paylink_filters?.policy_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          petition(
            getQueryString(pagination.current),
            false,
            false,
            false,
            pagination.current
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (agents && states && agents && products) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: [
          {
            title: "Date",
            inputs: [
              {
                label: "Agent",
                input: "selectAutoCompleteSearch",
                inputValue: !dataFilterModal
                  ? null
                  : dataFilterModal.parameters[0].inputs[0].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[0].checkboxValue,
                checkboxName: "checkbox_agents",
                options: agents,
                setOptions: searchAgents,
                key: "agent_id",
              },
              {
                label: "State",
                input: "selectAutoComplete",
                inputValue: !dataFilterModal
                  ? null
                  : dataFilterModal.parameters[0].inputs[1].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[1].checkboxValue,
                checkboxName: "checkbox_state",
                options: states,
                key: "state_id",
              },
              {
                label: "Product",
                input: "selectAutoComplete",
                inputValue: !dataFilterModal
                  ? null
                  : dataFilterModal.parameters[0].inputs[2].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[2].checkboxValue,
                checkboxName: "checkbox_product_id",
                options: products,
                key: "product_id",
              },
              {
                label: "Effective Date on or After",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[3].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[3].checkboxValue,
                checkboxName: "checkbox_after",
                key: "effective_after",
              },
              {
                label: "Effective Date on or Before",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[4].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[4].checkboxValue,
                checkboxName: "checkbox_before",
                key: "effective_before",
              },
            ],
          },
        ],
      });
    }
  }, [agents, states, products]);

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      <br></br>
      <br></br>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          states={states}
          agents={agents}
          products={products}
          allStates={states}
          loading={loadingModal}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
          }}
          loading={loadingModal}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              pagination={itemsPerPage.current}
            />
          )}
        </>
      )}
    </>
  );
}
