import React, { useState } from "react";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const Step12 = ({ agents, setExcludeAgents }) => {
  const [selectedExcludeAgents, setSelectedExcludeAgents] = useState([]);

  const handleExcludeAgentChange = (selectedOption) => {
    setSelectedExcludeAgents(selectedOption);
    setExcludeAgents(selectedOption)

  };

  return (
    <div style={{ display: "flex", flexDirection: "column", marginRight: "10%", marginLeft: "10%" }}>
      <br></br>
      <div style={{ marginTop: "20px" }}>Exclude Agents</div>
      <div style={{ marginBottom: "10px" }} className="agentesExclude">
        <Select
          value={selectedExcludeAgents}
          onChange={handleExcludeAgentChange}
          options={agents}
          isMulti
          isClearable={true}
          isSearchable={true}
          placeholder="Select agents to exclude..."
        />
        {/* <ButtonStyled
          onClickFunction={addExcludeAgent}
          style={{ height: "50px !important" }}
          text={"Add"}
        /> */}
      </div>
      {/* <button onClick={()=>console.log(selectedOption)}>test</button> */}

    </div>
  );
};

export default Step12;