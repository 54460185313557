import { useEffect, useState, useRef } from "react";
import { searchAgentsService } from "../../services/searchAgentsService";
import axios from "axios";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import getValuesInObject from "../../jsx/utils/getValuesInObject";
import Loading from "../../jsx/utils/Loading";
import ModalDetails from "./ModalUtility/ModalDetails";
import BulkDelete from "./ModalUtility/BulkDelete";
import json from "../../config.json"

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const filterRef = useRef();
  const filterText = useRef("");
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const parameterFilter = useRef({
    parameterFilter: "",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [agents, setAgents] = useState(null);
  const [agentsDefault, setAgentsDefault] = useState(null);
  const [carriers, setCarriers] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAll, setSelectAll] = useState(false)
  const [bulkDelete, setBulkDelete] = useState(false)
  const [form, setForm] = useState({
    pk: null,
    name: "",
    created_at: "",
    updated_at: "",
  });

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const transactionTypes = [
    { label: "commission", value: "commission" },
    { label: "payout", value: "payout" },
    { label: "chargeback", value: "chargeback" },
  ];

  const onHidde = () => {
    console.log("hidde");
  };

  const searchAgents = (e) => {
    searchAgentsService(agentsDefault, setAgents, e);
  };

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    let filtersActive = getValuesInObject(formLabels);
    setLoadingFilter(true);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter =
      getQueryString(paginationTemp) + (filterText.current || "") + filterModal;

    parameterFilter.current.parameterFilter = filterModal;

    let parameters = [
      {
        title: "Date",
        inputs: [
          {
            label: "Premium",
            input: "text",
            inputValue: form.commissionable_amount || "",
            checkbox: true,
            checkboxValue: form.commissionable_amount ? true : false,
            checkboxName: "checkbox_commissionable_amount",
            key: "commissionable_amount",
          },
          {
            label: "Amount",
            input: "text",
            inputValue: form.amount || "",
            checkbox: true,
            checkboxValue: form.amount ? true : false,
            checkboxName: "checkbox_amount",
            key: "amount",
          },
          {
            label: "Carrier",
            input: "selectAutoComplete",
            inputValue: form.carrier_id || {},
            checkbox: true,
            checkboxValue: form.carrier_id ? true : false,
            checkboxName: "checkbox_carrier",
            options: carriers,
            key: "carrier_id",
          },
          {
            label: "Agent",
            input: "selectAutoCompleteSearch",
            inputValue: form.agent_id || {},
            checkbox: true,
            checkboxValue: form.agent_id ? true : false,
            checkboxName: "checkbox_agents",
            options: agents,
            setOptions: searchAgents,
            key: "agent_id",
          },
          {
            label: "Policie",
            input: "selectAutoComplete",
            inputValue: form.policy_id || {},
            checkbox: true,
            checkboxValue: form.policy_id ? true : false,
            checkboxName: "checkbox_policy",
            options: policies,
            key: "policy_id",
          },
          {
            label: "Transaction type",
            input: "selectAutoComplete",
            inputValue: form.transaction_type || {},
            checkbox: true,
            checkboxValue: form.transaction_type ? true : false,
            checkboxName: "checkbox_transaction_type",
            options: transactionTypes,
            key: "transaction_type",
          },
          {
            label: "Transaction Date on or After",
            input: "date",
            inputValue: form.transaction_after || "",
            checkbox: true,
            checkboxValue: form.transaction_after ? true : false,
            checkboxName: "checkbox_after",
            key: "transaction_after",
          },
          {
            label: "Transaction Date or Before",
            input: "date",
            inputValue: form.transaction_before || "",
            checkbox: true,
            checkboxValue: form.transaction_before ? true : false,
            checkboxName: "checkbox_before",
            key: "transaction_before",
          },
        ],
      },
    ];

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: parameters,
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          transaction_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    }

    if (!filterText.current) {
      await petition(parameter, false, false, false, false, filtersActive);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("amount");
      await petition(parameter, false, true, value, false, filtersActive);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = false;
    setFilterModal(false);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    setBulkDelete(false)
    resetForm();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };

      parameter =
        getQueryString(paginationTemp) +
        (filterText.current || "") +
        (filterModal.current || "");

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[2] : false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) +
        (filterText.current || "") +
        (filterModal.current || "");

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[2] : false,
        pagination.current,
        objectFilter.current
      );
    }
  };

  const activeModalDetails = (e) => {
    setInfo(e);
    setModalDetails(true);
  };

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);
    let agentsTemp = [];
    let policiesTemp = [];
    let carriersTemp = [];

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;

    await petitionGet("carriers", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        result.result.unshift({
          label: "Select Option",
          value: "",
          isDisabled: true,
        });
        carriersTemp = result.result;
        setCarriers(result.result);
      })
      .catch((error) => {});
    await petitionGet("policies", { id, parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.policy_number;
        });
        policiesTemp = result.result;
        setPolicies(result.result);
      })
      .catch((error) => {});
    await petitionGet("agents", { id, parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.first_name + " " + element.last_name;
        });
        agentsTemp = result.result;
        setAgents(result.result);
        setAgentsDefault(result.result);
      })
      .catch((error) => {});

    await petitionGet("transactions", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = element.created_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.policeNumber = element?.policy?.policy_number;
          element.carrierName = carriersTemp.find(
            (e) => e.pk === element.carrier_id
          ).name;
          element.agentName = `${element?.agent?.first_name || ""} ${element?.agent?.last_name || ""}`;
          element.agency_commission = element?.agency_commission || 0;
          element.amount = element?.amount || 0;
          element.commissionable_amount = element?.commissionable_amount || 0;
          element.sub_agency_commission = element?.sub_agency_commission || 0;
          element.transaction_date = !element?.transaction_date ? "" : element?.transaction_date
            .slice(0, 10)
            .replaceAll("-", "/");
        });
        setDataTable({
          title: [
            { title: "TRANSACTION DATE", key: "transaction_date" },
            { title: "CARRIER", key: "carrierName" },
            { title: "AGENT", key: "agentName" },
            { title: "POLICY NUMBER", key: "policeNumber", link: true },
            { title: "TRANSACTION TYPE", key: "transaction_type" },
            { title: "COMMISSION AGENT", key: "amount" },
            { title: "PREMIUM", key: "commissionable_amount" },
            { title: "AGENCY COMMISSION", key: "agency_commission" },
            { title: "SUB AGENCY COMMISSION", key: "sub_agency_commission" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          clickItem: activeModalDetails,
          noColumnNumber: true,
          activeFilters: filtersToolip,
          addButton: sub_agency_id ? null : {
            label: "Add Transaction",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => {
            if (activeFilterTexBox) {
              activeItemsPerPage(e, true, valueFilter);
            } else {
              activeItemsPerPage(e);
            }
          },
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, valueFilter);
            } else {
              console.log(parameter);
              changePage(page);
            }
          },
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    setLoadingModal(true);
    let data = {
      agent_id: form.agent_id,
      policy_id: form.policy_id,
      transaction_type: form.transaction_type,
      amount: form.amount,
      carrier_id: form.carrier_id,
      transaction_date: form.transaction_date,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (info) {
      petitionPatch("transactions", {
        transaction_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("transactions", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("transactions", info?.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.error);
          setLoadingModal(false);
        } else {
          console.log(error);
        }
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
    });
  };

  const changePage = (page, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        (filterRef.current || "") +
        parameterFilter.current.parameterFilter;
      petition(
        parameter,
        true,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) +
        (filterRef.current || "") +
        parameterFilter.current.parameterFilter;
      petition(
        parameter,
        true,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&amount=${valueFilter}&commissionable_amount=${valueFilter}${filterRef.current}`;

      petition(
        parameter,
        false,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        false,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  const bulkDeleteTransactions = async () => {
    setLoadingModal(true)

    try {

      const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
      const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
      const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  
      let data = {}
  
      if (selectAll) {
        data = {}
      } else {
        data = {
          transactions: selectedTransactions,
        };
      }
  
      const headers = {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      };

      const filterModalQuery = parameterFilter.current.parameterFilter


      const query = `${subAgencyQuery ? `?${subAgencyQuery}` : ""}${filterModalQuery ? subAgencyQuery ? filterModalQuery : `?${filterModalQuery.slice(1)}` : ""}${selectAll && !filterModalQuery ? `${(subAgencyQuery || filterModalQuery) ? "&" : "?"}delete_all=true`: ""}`

      const url = `${urlBase}paylink360/${api_id}/bulk/transactions${query}`;
  
      await axios.delete(url, { headers, data })

      const newPaginationValue = {
        offset: 0,
        limit: 10,
      }

      const parameter = getQueryString(newPaginationValue) + filterModalQuery

      setLoadingModal(false)
      setBulkDelete(false)
      setSelectAll(false)
      setSelectedTransactions([])

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[2] : false,
        pagination.current,
        objectFilter.current
      );
      pagination.current = newPaginationValue
    } catch (error) {
      console.log(error)
    }
  }

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        const filters = result?.result?.paylink_filters?.transaction_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          petition(
            getQueryString(pagination.current),
            false,
            false,
            false,
            pagination.current
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    console.log(selectedTransactions)
  }, [selectedTransactions])

  useEffect(() => {
    if (agents && carriers && policies) {
      let parameters = [
        {
          title: "Date",
          inputs: [
            {
              label: "Premium",
              input: "text",
              inputValue: !dataFilterModal
                ? ""
                : dataFilterModal.parameters[0].inputs[0].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[0].checkboxValue,
              checkboxName: "checkbox_commissionable_amount",
              key: "commissionable_amount",
            },
            {
              label: "Amount",
              input: "text",
              inputValue: !dataFilterModal
                ? ""
                : dataFilterModal.parameters[0].inputs[1].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[1].checkboxValue,
              checkboxName: "checkbox_amount",
              key: "amount",
            },
            {
              label: "Carrier",
              input: "selectAutoComplete",
              inputValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[2].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[2].checkboxValue,
              checkboxName: "checkbox_carrier",
              options: carriers,
              key: "carrier_id",
            },
            {
              label: "Agent",
              input: "selectAutoCompleteSearch",
              inputValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[3].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[3].checkboxValue,
              checkboxName: "checkbox_agents",
              options: agents,
              setOptions: searchAgents,
              key: "agent_id",
            },
            {
              label: "Policie",
              input: "selectAutoComplete",
              inputValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[4].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[4].checkboxValue,
              checkboxName: "checkbox_policy",
              options: policies,
              key: "policy_id",
            },
            {
              label: "Transaction type",
              input: "selectAutoComplete",
              inputValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[5].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? null
                : dataFilterModal.parameters[0].inputs[5].checkboxValue,
              checkboxName: "checkbox_transaction_type",
              options: transactionTypes,
              key: "transaction_type",
            },
            {
              label: "Transaction Date on or After",
              input: "date",
              inputValue: !dataFilterModal
                ? ""
                : dataFilterModal.parameters[0].inputs[6].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[6].checkboxValue,
              checkboxName: "checkbox_after",
              key: "transaction_after",
            },
            {
              label: "Transaction Date or Before",
              input: "date",
              inputValue: !dataFilterModal
                ? ""
                : dataFilterModal.parameters[0].inputs[7].inputValue,
              checkbox: true,
              checkboxValue: !dataFilterModal
                ? false
                : dataFilterModal.parameters[0].inputs[7].checkboxValue,
              checkboxName: "checkbox_before",
              key: "transaction_before",
            },
          ],
        },
      ];

      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: parameters,
      });
    }
  }, [agents, carriers, policies]);

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      <br></br>
      <br></br>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          loading={loadingModal}
          carriers={carriers}
          policies={policies}
          agents={agents}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
            setErrorMessage(null);
            setInfo(null);
          }}
          loading={loadingModal}
          info={info}
          errorMessage={errorMessage}
        />
      )}

      {modalDetails && (
        <ModalDetails
          modal={modalDetails}
          info={info}
          closeModal={() => {
            setInfo(null);
            setModalDetails(false);
          }}
        />
      )}

      {bulkDelete && (
        <BulkDelete 
          openModal={bulkDelete}
          modalActions={bulkDeleteTransactions}
          closeModal={() => setBulkDelete(false)}
          noShowActionButton={!selectedTransactions.length}
          loading={loadingModal}
          selectedTransactions={selectAll ? allResult.item_count : selectedTransactions.length}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              styleCardTable={{ overflow: "auto" }}
              pagination={itemsPerPage.current}
              columnCheckboxs={{
                name: "Actions: ",
                actions: [
                  {
                    name: "Delete Transactions",
                    icon: <DeleteForeverIcon />,
                    handleClick: () =>  setBulkDelete(true),
                  }
                ]
              }}
              selectedRows={selectedTransactions}
              setSelectedRows={setSelectedTransactions}
              selectAllRows={selectAll}
              setSelectAllRows={setSelectAll}
              module="Transaction(s)"
            />
          )}
        </>
      )}
    </>
  );
}
