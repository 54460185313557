import { useEffect, useState, useRef,useReducer } from "react";
import petitionGet from "../../../services/petitionGet";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomTable from "../../../jsx/utils/CustomTable/CustomTable";
import ModalDelete from "../ModalUtility/ModalDelete";
import ModalFilter from "../../../jsx/utils/ModalFilter";
import petitionDelete from "../../../services/petitionDelete";
import getQueryString from "../../../jsx/utils/getQueryString";
import Loading from "../../../jsx/utils/Loading";
import getValuesInObject from "../../../jsx/utils/getValuesInObject";
import petitionPatch from "../../../services/petitionPatch";
import { searchSubAgencyFromSubAgencyId } from "./service/searchSubAgencyFromSubAgencyId";

const Agency = () => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const filterRef = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [loadingMenu, setLoadingMenu] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;
  const defaultAgencyname = useRef("");

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    let filtersActive = getValuesInObject(formLabels);
    setLoadingFilter(true);
    setFirstLoad(false);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter =
      getQueryString(paginationTemp) + filterModal + "&agent_id=null";

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Date",
          inputs: [
            {
              label: "Payout Created On or After",
              input: "date",
              inputValue: form.added_after || "",
              checkbox: true,
              checkboxValue: form.added_after ? true : false,
              checkboxName: "checkbox_after",
              key: "added_after",
            },
            {
              label: "Payout Created On or Before",
              input: "date",
              inputValue: form.added_before || "",
              checkbox: true,
              checkboxValue: form.added_before ? true : false,
              checkboxName: "checkbox_before",
              key: "added_before",
            },
          ],
        },
      ],
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          agency_payment_history_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => { })
        .catch((error) => console.log(error));
    }

    await petition(parameter, false, false, filtersActive);

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalDelete(false);
    setInfo(null);

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter =
        getQueryString(paginationTemp) +
        (filterRef.current || "") +
        "&agent_id=null";
      petition(parameter, false, paginationTemp, objectFilter.current);
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) +
        (filterRef.current || "") +
        "&agent_id=null";
      petition(parameter, false, pagination.current, objectFilter.current);
    }
  };

  const activeDownloadReport = (e) => {
    setLoadingMenu(e);
    let payout_id = e.pk;
    petitionGet("payoutPdfDownload", { payout_id, parameter: "?agency=true" })
      .then(({ data: result }) => {
        let link = document.createElement("a");
        link.download = `${e.agentName}.pdf`;
        link.href = `data:application/pdf;base64,${result.pdf}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setLoadingMenu(null);
      })
      .catch((error) => console.log(error));
  };

  const petition = async (
    parameter,
    noLoading,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);

    const completeParameter = `${parameter || ""}${subAgencyQuery
      ? parameter
        ? `&${subAgencyQuery}`
        : `?${subAgencyQuery}`
      : ""
      }`;

    await petitionGet("payouts", { parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);

        result.result.forEach((element, i) => {
          searchSubAgencyFromSubAgencyId(element.sub_agency_id, element, forceUpdate, defaultAgencyname.current)
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.date = element.payout_date
            .substring(0, 10)
            .replaceAll("-", "/");
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "AMOUNT", key: "amount" },
            { title: "Agency", key: "subAgencyName" },
            { title: "PAYOUT DATE", key: "date" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Download Report",
                icon: <DownloadIcon />,
                handleClick: activeDownloadReport,
              },
              sub_agency_id ? {} : {
                name: "Delete Report",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
          iconAction: [
            {
              icon: <RefreshIcon className="icons-contacts" />,
              handleClick: refreshPage,
            },
          ],
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        setFilterModal(false);
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("payouts", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter =
      getQueryString(paginationTemp) +
      (filterRef.current || "") +
      "&agent_id=null";
    petition(parameter, true, paginationTemp, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter =
      getQueryString(paginationTemp) +
      (filterRef.current || "") +
      "&agent_id=null";
    petition(parameter, false, paginationTemp, objectFilter.current);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const refreshPage = () => {
    setLoadingIcon(true);
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };
    let parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    petition(parameter, true, paginationTemp, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        defaultAgencyname.current = result.result.agency_name
        const filters =
          result?.result?.paylink_filters?.agency_payment_history_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          setDataFilterModal({
            onSaveFunction: (form, formLabels) =>
              onSaveFunction(form, formLabels),
            onHidde: () => onHidde(),
            parameters: [
              {
                title: "Date",
                inputs: [
                  {
                    label: "Payout Created On or After",
                    input: "date",
                    inputValue: "",
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: "checkbox_after",
                    key: "added_after",
                  },
                  {
                    label: "Payout Created On or Before",
                    input: "date",
                    inputValue: "",
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: "checkbox_before",
                    key: "added_before",
                  },
                ],
              },
            ],
          });
          petition(
            getQueryString(pagination.current) + "&agent_id=null",
            false,
            pagination.current,
            false
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            setModalDelete(false);
            setInfo(null);
          }}
          loading={loadingModal}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              pagination={itemsPerPage.current}
              loadingMenu={loadingMenu}
              loading={loadingIcon}
            />
          )}
        </>
      )}
    </>
  );
};

export default Agency;
