import React, { useRef, useState, useEffect } from "react";
import { formatHourof24at12 } from "../../utils/GetChatDate";
import getQueryString from "../../jsx/utils/getQueryString";
import petitionGet from "../../services/petitionGet";
import Loading from "../../jsx/utils/Loading";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalView from "./ModalUtility/ModalView";
import RefreshIcon from "@mui/icons-material/Refresh";

const ImportJobs = () => {
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const formatData = (element) => {
    let date = element.slice(0, 10).replaceAll("-", "/");
    let month = date.split("/")[1];
    let day = date.split("/")[2];
    let year = date.split("/")[0];
    let hour = formatHourof24at12(element);

    return month + "/" + day + "/" + year + " " + hour;
  };

  const capitalizeFirstLetter = (string) => {
    let capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  };

  const activeViewErrors = (e) => {
    setInfo(e);
    setModalView(true);
  };

  const closeModal = () => {
    setInfo(null);
    setModalView(false);
  };

  const refreshPage = () => {
    setLoadingIcon(true);
    let parameter = getQueryString(pagination.current);
    petition(parameter, pagination.current, true);
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const petition = (parameter, paginationTemp, noLoading) => {
    if (!noLoading) setLoading(true);
    petitionGet("import-jobs", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((job, i) => {
          job.positionTable = (paginationTemp?.offset || 0) + i + 1;
          // job.date = formatData(job.created_at);
          job.date = job.created_at.slice(0, 10).replaceAll("-", "/");
          job.state = capitalizeFirstLetter(job.status);
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "Date", key: "date" },
            { title: "Status", key: "state" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Error",
                icon: <VisibilityIcon />,
                handleClick: activeViewErrors,
              },
            ],
          },
          noColumnNumber: true,
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
          iconAction: [
            {
              icon: <RefreshIcon className="icons-contacts" />,
              handleClick: refreshPage,
            },
          ],
        });
        setLoading(false);
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current), pagination.current);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalView && (
        <ModalView modal={modalView} closeModal={closeModal} info={info} />
      )}

      {dataTable && (
        <CustomTable
          data={dataTable}
          noClass={true}
          allResult={allResult}
          pagination={itemsPerPage.current}
          loading={loadingIcon}
        />
      )}
    </>
  );
};

export default ImportJobs;
