import { useEffect, useState, useRef,useReducer } from "react";
import { searchAgentsService } from "../../../services/searchAgentsService";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CustomTable from "../../../jsx/utils/CustomTable/CustomTable";
import petitionGet from "../../../services/petitionGet";
import ModalDelete from "../ModalUtility/ModalDelete";
import ModalFilter from "../../../jsx/utils/ModalFilter";
import ModalSend from "../ModalUtility/ModalSend";
import petitionPatch from "../../../services/petitionPatch";
import petitionDelete from "../../../services/petitionDelete";
import getQueryString from "../../../jsx/utils/getQueryString";
import Loading from "../../../jsx/utils/Loading";
import getValuesInObject from "../../../jsx/utils/getValuesInObject";
import {
  sendToastError,
  sendToastSuccess,
} from "../../../jsx/utils/toastAlert";
import { agencyNameValidator } from "./service/agencyService";

export default function Agents() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const filterRef = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentsDefault, setAgentsDefault] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [loadingMenu, setLoadingMenu] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [allPayouts, setAllPayouts] = useState([]);
  const [modalSend, setModalSend] = useState(false);
  const defaultAgencyname = useRef("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const onHidde = () => {
    console.log("hidde");
  };

  const searchAgents = (e) => {
    searchAgentsService(agentsDefault, setAgents, e);
  };

  const getAllPayouts = async (parameter) => {
    const formatedParam = parameter ? "?" + parameter.slice(1) : ""
    const completeParameter = `${formatedParam || ""}${
      subAgencyQuery
        ? formatedParam
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;
    await petitionGet("payouts", { parameter: completeParameter })
      .then(({ data: result }) => {
        let allAgents = []

        result.result.forEach((element) => {
          allAgents.push(`${element?.agent?.first_name} ${element?.agent?.last_name || ""}`);
        });

        const allAgentsWithoutDuplicates = [ ...new Set(allAgents) ]
        setAllPayouts(allAgentsWithoutDuplicates);
      })
      .catch((error) => console.log(error))
  }

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    let filtersActive = getValuesInObject(formLabels);
    setLoadingFilter(true);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");

    let parameter = getQueryString(paginationTemp) + filterModal;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Date",
          inputs: [
            {
              label: "Agent",
              input: "selectAutoCompleteSearch",
              inputValue: form.agent_id || {},
              checkbox: true,
              checkboxValue: form.agent_id ? true : false,
              checkboxName: "checkbox_agents",
              options: agents,
              setOptions: searchAgents,
              key: "agent_id",
            },
            {
              label: "Payout Created On or After",
              input: "date",
              inputValue: form.added_after || "",
              checkbox: true,
              checkboxValue: form.added_after ? true : false,
              checkboxName: "checkbox_after",
              key: "added_after",
            },
            {
              label: "Payout Created On or Before",
              input: "date",
              inputValue: form.added_before || "",
              checkbox: true,
              checkboxValue: form.added_before ? true : false,
              checkboxName: "checkbox_before",
              key: "added_before",
            },
          ],
        },
      ],
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          agent_payment_history_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => {}) 
        .catch((error) => console.log(error));
    }

    await getAllPayouts(filterModal)

    await petition(parameter, false, false, filtersActive);

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalDelete(false);
    setInfo(null);
    setModalSend(false);

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp) + (filterRef.current || "");
      petition(parameter, false, paginationTemp, objectFilter.current);
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) + (filterRef.current || "");
      petition(parameter, false, pagination.current, objectFilter.current);
    }
  };

  const activeDownloadReport = (e) => {
    setLoadingMenu(e);
    let payout_id = e.pk;
    petitionGet("payoutPdfDownload", { payout_id })
      .then(({ data: result }) => {
        let link = document.createElement("a");
        link.download = `${e.agentName}.pdf`;
        link.href = `data:application/pdf;base64,${result.pdf}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setLoadingMenu(null);
      })
      .catch((error) => {
        console.log(error);
        sendToastError();
      });
  };

  const petition = async (
    parameter,
    noLoading,
    paginationTemp,
    filtersToolip,
  ) => {
    if (!noLoading) setLoading(true);

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;

    await petitionGet("agents", { id, parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.first_name + " " + element.last_name;
        });
        setAgents(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("payouts", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach(async(element, i) => {
          agencyNameValidator(element.agent.sub_agency_id, defaultAgencyname.current, petitionGet, subAgencyItem, element, forceUpdate, sub_agency_id)
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.date = element.payout_date
            .substring(0, 10)
            .replaceAll("-", "/");

          element.agentName =
            element.agent.first_name + " " + element.agent.last_name;
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "AGENT", key: "agentName" },
            { title: "AGENCY", key: "agencyName" },
            { title: "AMOUNT", key: "amount" },
            { title: "PAYOUT DATE", key: "date" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Download Report",
                icon: <DownloadIcon />,
                handleClick: activeDownloadReport,
              },
              sub_agency_id ? {} : {
                name: "Delete Report",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
          iconAction: [
            {
              icon: <RefreshIcon className="icons-contacts" />,
              handleClick: refreshPage,
            },
            {
              icon: <EmailOutlinedIcon className="icons-contacts" />,
              handleClick: activeModalSendEmail,
            },
          ],
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        setFilterModal(false);
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("payouts", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    petition(parameter, true, paginationTemp, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    petition(parameter, false, paginationTemp, objectFilter.current);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const refreshPage = () => {
    setLoadingIcon(true);
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };
    let parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    petition(parameter, true, paginationTemp, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const activeModalSendEmail = () => {
    setModalSend(true);
  };

  const sendEmailFunction = () => {
    setLoadingModal(true);
    let parameter = filterRef.current ? "?" + filterRef.current.slice(1) : "";
    petitionGet("send-email-payouts", { parameter })
      .then(({ data: result }) => {
        setLoadingModal(false);
        setModalSend(false);
        sendToastSuccess();
      })
      .catch((error) => {
        console.log(error);
        setLoadingModal(false);
        setModalSend(false);
        sendToastError();
      });
  };

  const getDate = () => {
    const today = new Date();
    const formatedToday = today.toISOString().split("T")[0];

    // Obtener el lunes anterior
    const dayWeek = today.getDay(); // 0 (domingo) a 6 (sábado)
    const subtractDays = dayWeek === 0 ? 6 : dayWeek - 1;
    const dateMondayBefore = new Date(today);
    dateMondayBefore.setDate(today.getDate() - subtractDays);
    const dateMondayPreviousFormatted = dateMondayBefore
      .toISOString()
      .split("T")[0];

    return [formatedToday, dateMondayPreviousFormatted];
  };

  const afterAccountDetails = async (dateMondayPrevious, today, filtersActive) => {
    const dateParameter = `&added_after=${dateMondayPrevious}&added_before=${today}`
    const  parameter = getQueryString(pagination.current) + `&added_after=${dateMondayPrevious}&added_before=${today}`;

    await getAllPayouts(dateParameter)

    await petition(parameter, false, pagination.current, filtersActive);
    filterRef.current = `&added_after=${dateMondayPrevious}&added_before=${today}`;
    objectFilter.current = filtersActive;
  }

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        defaultAgencyname.current = result.result.agency_name
        const filters = result?.result?.paylink_filters?.agent_payment_history_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          let form = filters;
          let formLabels = form.formLabels;

          if (!form.added_after && !form.added_before) {
            const [today, dateMondayPrevious] = getDate();
            form = {
              ...form,
              added_after: dateMondayPrevious,
              added_before: today,
            };

            formLabels = {
              ...formLabels,
              "Payout Created On or After": dateMondayPrevious,
              "Payout Created On or Before": today,
            };

            accounFilters.current = {
              ...accounFilters.current,
              agent_payment_history_filters: { ...form },
            };
          }

          delete form.formLabels;
          onSaveFunction(form, formLabels, true);
        } else {
          const [today, dateMondayPrevious] = getDate();
          let filtersActive = {
            ["Payout Created On or After"]: dateMondayPrevious,
            ["Payout Created On or Before"]: today,
          };

          setDataFilterModal({
            onSaveFunction: (form, formLabels) =>
              onSaveFunction(form, formLabels),
            onHidde: () => onHidde(),
            parameters: [
              {
                title: "Date",
                inputs: [
                  {
                    label: "Agent",
                    input: "selectAutoCompleteSearch",
                    inputValue: {},
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: "checkbox_agents",
                    options: agents,
                    setOptions: searchAgents,
                    key: "agent_id",
                  },
                  {
                    label: "Payout Created On or After",
                    input: "date",
                    inputValue: dateMondayPrevious,
                    checkbox: true,
                    checkboxValue: true,
                    checkboxName: "checkbox_after",
                    key: "added_after",
                  },
                  {
                    label: "Payout Created On or Before",
                    input: "date",
                    inputValue: today,
                    checkbox: true,
                    checkboxValue: true,
                    checkboxName: "checkbox_before",
                    key: "added_before",
                  },
                ],
              },
            ],
          });

          afterAccountDetails(dateMondayPrevious, today, filtersActive)
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (agents) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: [
          {
            title: "Date",
            inputs: [
              {
                label: "Agent",
                input: "selectAutoCompleteSearch",
                inputValue: !dataFilterModal
                  ? null
                  : dataFilterModal.parameters[0].inputs[0].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[0].checkboxValue,
                checkboxName: "checkbox_agents",
                options: agents,
                setOptions: searchAgents,
                key: "agent_id",
              },
              {
                label: "Payout Created On or After",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[1].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[1].checkboxValue,
                checkboxName: "checkbox_after",
                key: "added_after",
              },
              {
                label: "Payout Created On or Before",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[2].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[2].checkboxValue,
                checkboxName: "checkbox_before",
                key: "added_before",
              },
            ],
          },
        ],
      });
    }
  }, [agents]);

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            setModalDelete(false);
            setInfo(null);
          }}
          loading={loadingModal}
        />
      )}

      {modalSend && (
        <ModalSend
          openModal={modalSend}
          modalActions={sendEmailFunction}
          closeModal={() => {
            setModalSend(false);
          }}
          info={allPayouts}
          loading={loadingModal}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              pagination={itemsPerPage.current}
              loadingMenu={loadingMenu}
              loading={loadingIcon}
            />
          )}
        </>
      )}
    </>
  );
}
