const getChatDate = (value) => {
  if (value === "now") return `now`;
  const date = new Date(value);
  let newDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  const time =
    Math.round(new Date().getTime() / 1000) - Math.round(newDate / 1000);

  let result = null;
  if (time < 60) {
    result = `${time} seconds ago`;
  } else if (time < 3600) {
    result = `${Math.round(time / 60)} minutes ago`;
  } else if (time < 86400) {
    result = `${Math.round(time / 3600)} hours ago`;
  } else if (time < 604800) {
    result = `${Math.round(time / 86400)} days ago`;
  } else if (time < 2592000) {
    result = `${Math.round(time / 604800)} weeks ago`;
  } else if (31104000) {
    result = `${Math.round(time / 2592000)} months ago`;
  } else {
    result = `${Math.round(time / 31104000)} years ago`;
  }
  return result;
};
const getOtherChatDate = (value) => {
  const date = new Date(value);
  let newDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  const time =
    Math.round(new Date().getTime() / 1000) - Math.round(newDate / 1000);
  let result = null;
  if (time < 60) {
    result = `${time} seconds ago`;
  } else if (time < 3600) {
    result = `${Math.round(time / 60)} minutes ago`;
  } else if (time < 86400) {
    result = `${Math.round(time / 3600)} hours ago`;
  } else if (time < 604800) {
    result = `${Math.round(time / 86400)} days ago`;
  } else if (time < 2592000) {
    result = `${Math.round(time / 604800)} weeks ago`;
  } else if (31104000) {
    result = `${Math.round(time / 2592000)} months ago`;
  } else {
    result = `${Math.round(time / 31104000)} years ago`;
  }
  return result;
};

const formatHourof24at12 = (date) => {
  let h = parseInt(date.split(" ")[1].split(":")[0]);
  let minutes = date.split(" ")[1].split(":")[1];
  let formatHour = ((h + 11) % 12) + 1;

  return `${formatHour}:${minutes} ${h >= 12 ? "PM" : "AM"}`;
};

const selectedMonths = (number) => {
  switch (true) {
    case number === "01" || number === "1":
      return "January";
    case number === "02" || number === "2":
      return "February";
    case number === "03" || number === "3":
      return "March";
    case number === "04" || number === "4":
      return "April";
    case number === "05" || number === "5":
      return "May";
    case number === "06" || number === "6":
      return "June";
    case number === "07" || number === "7":
      return "July";
    case number === "08" || number === "8":
      return "August";
    case number === "09" || number === "9":
      return "September";
    case number === "010" || number === "10":
      return "October";
    case number === "011" || number === "11":
      return "November";
    case number === "012" || number === "12":
      return "December";
  }
};

export { getChatDate, getOtherChatDate, formatHourof24at12, selectedMonths };
