import { useEffect, useState } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import { searchAgentsService } from "../../services/searchAgentsService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingAddLead, setLoadingAddLead] = useState(false);
  const [loadingDeleteLead, setLoadingDeleteLead] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentsDefault, setAgentsDefault] = useState(null);

  const [form, setForm] = useState({
    start_date: null,
    end_date: null,
    agency_id: null,
    agent_id: null,
  });

  useEffect(() => {
    petition();
  }, []);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (formData) => {
    setLoadingFilter(true);
    let parameter = getQueryString(formData);
    petition(parameter);
    // setFilterModal(false);
  };

  const searchAgents = (e) => {
    searchAgentsService(agentsDefault, setAgents, e);
  };

  const dataFilterModal = {
    onSaveFunction: (formData) => onSaveFunction(formData),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Contract Start Date",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_start",
            key: "start_date",
          },
          {
            label: "Contract End Date",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_end",
            key: "end_date",
          },
          {
            label: "Contract Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "added_after",
          },
          {
            label: "Contract Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "added_before",
          },
          {
            label: "Agency",
            input: "select",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_agencies",
            options: agencies,
            key: "agencies_id",
          },
          {
            label: "Agent",
            input: "selectAutoCompleteSearch",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_agents",
            options: agents,
            setOptions: searchAgents,
            key: "agent_id",
          },
        ],
      },
    ],
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = () => {
    setModalDelete(false);
    setModalAdd(false);
    setInfo(null);
    resetForm();
    petition();
  };

  const petition = async (parameter) => {
    setLoading(true);
    await petitionGet("agents", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.first_name + " " + element.last_name;
        });
        setAgents(result.result);
      })
      .catch((error) => {});
    await petitionGet("agencies", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        setAgencies(result.result);
      })
      .catch((error) => {});
    await petitionGet("contracts", { id, parameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.created_at = element.created_at
            .substring(0, 16)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 16)
            .replaceAll("-", "/");
          element.start_date = element.start_date
            .substring(0, 16)
            .replaceAll("-", "/");
          element.end_date = element.end_date
            .substring(0, 16)
            .replaceAll("-", "/");
          element.agencyName = element.agency.name;
          element.agentName =
            element.agent.first_name + " " + element.agent.last_name;
        });
        setDataTable({
          title: [
            { title: "AGENCY NAME", key: "agencyName" },
            { title: "AGENT NAME", key: "agentName" },
            { title: "START DATE", key: "start_date" },
            { title: "END DATE", key: "end_date" },
            { title: "CREATED ON", key: "created_at" },
            { title: "UPDATED ON", key: "updated_at" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeEditModal,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          search: {
            label: "Search Contract",
          },
          addButton: {
            label: "Add contract",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
        });
        setLoading(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    let data = {
      agency_id: form.agency_id,
      agent_id: form.agent_id,
      start_date: form.start_date.replaceAll("/", "-").substring(0, 10),
      end_date: form.end_date.replaceAll("/", "-").substring(0, 10),
    };

    console.log(data);
    if (info) {
      petitionPatch("contracts", {
        contracts_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("contracts", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const deleteFunction = () => {
    petitionDelete("contracts", info.pk)
      .then(({ data: { result } }) => {
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      start_date: null,
      end_date: null,
      agency_id: null,
      agent_id: null,
    });
  };
  return (
    <>
      <ModalFilter
        openModal={filterModal}
        closeModal={() => {
          setFilterModal(false);
        }}
        info={dataFilterModal}
        loading={loadingFilter}
      />
      <ModalAdd
        openModal={modalAdd}
        modalActions={addData}
        closeModal={() => {
          resetForm();
          setModalAdd(false);
        }}
        form={form}
        setForm={setForm}
        loading={loadingAddLead}
        agencies={agencies}
        agents={agents}
      />
      <ModalDelete
        openModal={modalDelete}
        modalActions={deleteFunction}
        closeModal={() => {
          resetForm();
          setModalDelete(false);
        }}
        loading={loadingDeleteLead}
      />
      {loading ? (
        <Loading />
      ) : (
        dataTable && <CustomTable data={dataTable} noClass={true} />
      )}
    </>
  );
}
