import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import CustomVerticalTable from "../../../jsx/utils/CustomVerticalTable/CustomVerticalTable";

const ModalSend = ({ openModal, modalActions, closeModal, info, loading }) => {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    setDataTable({
      onlyKey: true,
      key: "agentName",
      content: info,
    });
  }, []);

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are about to send an Email for the following Agents:</p>
        {dataTable && <CustomVerticalTable dataTable={dataTable} />}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          onClickFunction={() => closeModal()}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text="Send"
          onClickFunction={() => modalActions()}
          disabled={!info.length}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSend;
